module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Alterna","short_name":"Alterna","start_url":"/","display":"standalone","icon":"src/favicon/alterna/favicon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"2c8586c7c28f244ab381eac4d4712549"},
    },{
      plugin: require('../node_modules/@prismicio/gatsby-source-prismic-graphql/gatsby-browser.js'),
      options: {"plugins":[],"repositoryName":"alterna"},
    },{
      plugin: require('../node_modules/gatsby-plugin-breakpoints/gatsby-browser.js'),
      options: {"plugins":[],"queries":{"medium":"(min-width: 750px)","large":"(min-width: 1000px)","xlarge":"(min-width: 1300px)","xxlarge":"(min-width: 1600px)","max":"(min-width: 1900px)"}},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
