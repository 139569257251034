// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-forstander-job-1-jsx": () => import("./../../../src/pages/forstander-job-1.jsx" /* webpackChunkName: "component---src-pages-forstander-job-1-jsx" */),
  "component---src-pages-tilmeld-success-jsx": () => import("./../../../src/pages/tilmeld-success.jsx" /* webpackChunkName: "component---src-pages-tilmeld-success-jsx" */),
  "component---src-templates-page-jsx": () => import("./../../../src/templates/page.jsx" /* webpackChunkName: "component---src-templates-page-jsx" */)
}

