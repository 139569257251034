const initialState = {
  navMenuToggled: false,
  isScrolled: false,
}

const TOGGLE_NAV_MENU = "TOGGLE_NAV_MENU"
const SET_SCROLL = "SET_SCROLL"

export const toggleNavMenu = navMenuToggled => ({
  type: TOGGLE_NAV_MENU,
  navMenuToggled,
})

export const setScroll = isScrolled => ({
  type: SET_SCROLL,
  isScrolled,
})

export default (state = initialState, action) => {
  switch (action.type) {
    case TOGGLE_NAV_MENU:
      return { ...state, navMenuToggled: action.navMenuToggled }
    case SET_SCROLL:
      return { ...state, isScrolled: action.isScrolled }
    default:
      return state
  }
}
