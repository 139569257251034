import React from "react"
import { Provider } from "react-redux"
import createStore from "./store"
import { persistState } from "./persistState"

export default ({ element }) => {
  const store = createStore()

  store.subscribe(() => {
    persistState.save(store.getState())
  })

  return <Provider store={store}>{element}</Provider>
}
