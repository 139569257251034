const load = () => {
  try {
    if (typeof window !== "undefined" && window.localStorage) {
      const serializedState = localStorage.getItem("state")

      if (serializedState === null) {
        return undefined
      }

      return JSON.parse(serializedState)
    }
  } catch (error) {
    return undefined
  }
}

const save = state => {
  try {
    if (typeof window !== "undefined" && window.localStorage) {
      const serializedState = JSON.stringify(state)
      localStorage.setItem("state", serializedState)
    }
  } catch (err) {
    // Ignore write errors, but log in time possibly.
  }
}

const persistState = {
  load,
  save,
}

export { persistState }
