import { createStore as reduxCreateStore } from "redux"
import rootReducer from "./reducers/rootReducer"
import { persistState } from "./persistState"

const createStore = () => {
  const persistedState = persistState.load()
  const store = reduxCreateStore(rootReducer, persistedState)

  store.subscribe(() => {
    persistState.save(store.getState())
  })

  return store
}

export default createStore
