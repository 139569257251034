import WrapWithProvider from "./src/state/wrapWithProvider"
// import createStore from "./src/state/store"
// import { toggleNavMenu } from "./src/state/reducers/global"

// const store = createStore()

// export const onRouteUpdate = ({ location, prevLocation }) => {
//   store.dispatch(toggleNavMenu(false))
// }

export const wrapRootElement = WrapWithProvider
